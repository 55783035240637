<template>
  <section class="bg-secondary py-10 grey lighten-4 primary--text">
    <v-container>
      <v-row>
        <v-col>
          <v-row no-gutters>
            <v-col cols="12" class="text-center my-10">
              <h2
                class="text-h4 text-md-h3 text-center font-weight-black text-capitalize mb-4"
              >
                <v-icon icon="mdi-shield-star"></v-icon>
                Louisiana’s Heroes
              </h2>

              <p style="text-align: left">
                Inscribed on the four marble walls surrounding the center of the
                Louisiana Law Enforcement Officers Memorial are the names of
                over 400 Louisiana Officers who made the ultimate sacrifice.
                Beginning with Louisiana’s first line of duty death in 1870,
                through the current year, these heroes are honored for their
                service to their respective communities and for the sacrifice
                they made protecting the citizens of Louisiana.
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
