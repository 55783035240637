<template>
  <v-row no-gutters>
    <v-col>
      <HeroSmall :heading="heading" />
    </v-col>
  </v-row>

  <GallerySection :galleryImages="galleryImages"></GallerySection>

  <QuoteSection quote="Protect and Serve" />
</template>

<script>
import HeroSmall from "@/components/sections/HeroSmall.vue";
import QuoteSection from "@/components/sections/QuoteSection.vue";
import GallerySection from "@/components/sections/gallerySection.vue";

export default {
  name: "GalleryView",

  components: {
    HeroSmall,
    QuoteSection,
    GallerySection,
  },

  data: () => ({
    heading: " Gallery ",
    galleryImages: [
      { name: "closeup" },
      { name: "family" },
      { name: "names" },
      { name: "crowd" },
      { name: "Helicopter_flyover" },
      { name: "hat" },
      { name: "mother" },
      { name: "statue" },
      { name: "crowd2" },
      { name: "etching" },
      { name: "memorial" },
      { name: "honor" },
    ],
  }),
};
</script>
