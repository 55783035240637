<template>
  <section class="bg-white py-10 grey lighten-4 primary--text">
    <v-container>
      <v-row>
        <v-col>
          <v-row no-gutters>
            <v-col cols="12" class="text-center my-10">
              <h2
                class="text-h4 text-md-h3 text-center font-weight-black text-capitalize mb-4"
              >
                <v-icon icon="mdi-newspaper"></v-icon>
                In the News
              </h2>

              <v-card class="ma-3 pa-4" color="grey-lighten-2" tile>
                <a
                  variant="text"
                  class="text-black link-text"
                  href="https://wgno.com/news/louisiana/lake-lawn-metairie-funeral-home-honors-louisiana-law-enforcement-at-21st-annual-memorial/"
                  target="_blank"
                  >Lake Lawn Metairie Funeral Home honors Louisiana law
                  enforcement at 21st annual memorial
                </a>
              </v-card>

              <v-card class="ma-3 pa-4" color="grey-lighten-2" tile>
                <a
                  class="text-black link-text"
                  href="https://tegpr.com/lake-lawn-metairie-funeral-home-and-cemeteries-honor-louisiana-law-enforcement-officers-at-annual-memorial/"
                  target="_blank"
                >
                  <p class="text-wrap">
                    Lake Lawn Metairie Funeral Home and Cemeteries Honors
                    Louisiana Law Enforcement Officers at Annual Memorial
                  </p>
                </a>
              </v-card>

              <v-card class="ma-3 pa-4" color="grey-lighten-2" tile>
                <a
                  variant="text"
                  class="text-black link-text"
                  href="https://www.wwno.org/show/louisiana-considered/2022-05-12/upcoming-peace-officers-memorial-day-pays-tribute-to-those-lost-in-the-line-of-duty"
                  target="_blank"
                >
                  Upcoming Peace Officers Memorial Day pays tribute to those
                  lost in the line of duty
                </a>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<style>
.link-text {
  text-decoration: none;
  font-weight: 500;
}
</style>
