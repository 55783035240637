<template>
  <v-app dark>
    <siteHeader />
    <v-main>
      <router-view />
    </v-main>
    <siteFooter />
  </v-app>
</template>

<script>
import siteHeader from './components/siteHeader.vue'
import siteFooter from './components/siteFooter.vue'

export default {
  name: 'App',

  components: {
    siteHeader,
    siteFooter
  },

  data: () => ({
    //
  }),
}
</script>
