// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#14213d',
    surface: '#14213d',
    primary: '#14213d',
    'primary-darken-1': '#000000',
    secondary: '#f9c13b',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#000000',
    success: '#4CAF50',
    warning: '#f9c13b',
    'bg-dark-blue': '#001d3d'
  }
}


export default createVuetify({
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    }
  }
})