<template>
  <v-row no-gutters>
    <v-col>
      <v-img
        :src="require(`@/assets/img/footer/flag.jpg`)"
        :lazy-src="require(`@/assets/img/footer/flag.jpg`)"
      >
      </v-img>
    </v-col>
    <v-col>
      <v-img
        :src="require(`@/assets/img/footer/statue_night.jpg`)"
        :lazy-src="require(`@/assets/img/footer/statue_night.jpg`)"
      >
      </v-img>
    </v-col>
    <v-col>
      <v-img
        :src="require(`@/assets/img/footer/sideview.jpg`)"
        :lazy-src="require(`@/assets/img/footer/sideview.jpg`)"
      >
      </v-img>
    </v-col>
    <v-col>
      <v-img
        :src="require(`@/assets/img/footer/patch_closeup.jpg`)"
        :lazy-src="require(`@/assets/img/footer/patch_closeup.jpg`)"
      >
      </v-img>
    </v-col>
  </v-row>

  <v-footer class="bg-primary">
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="4" class="text-center">
          <div class="title text-uppercase mt-1 mb-4">
            Memorial Site Address
          </div>
          <p>
            Lake Lawn Metairie Cemetery<br />
            5100 Pontchartrain Blvd<br />
            New Orleans, La 70124
          </p>
          <v-row no-gutters>
            <v-col cols="12"> </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="text-center">
          <div class="title text-uppercase mt-1 mb-4">Mailing Address</div>
          <p>
            PO Box 851050 <br />
            New Orleans, LA 70185<br />
            (For correspondence and donations)
          </p>
          <v-row no-gutters>
            <v-col cols="12"> </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="4" class="text-center">
          <div class="title text-uppercase mt-1 mb-4">
            Search for Louisiana’s Fallen Heroes
          </div>

          <v-row align="top">
            <v-col align="center">
              <a
                href="https://www.odmp.org/search/browse/louisiana"
                target="_blank"
                class="text-white"
              >
                <v-img
                  src="../assets/odmp.png"
                  width="60"
                  height="60"
                  class="mb-2"
                />
                Officer Down <br />
                Memorial Page
              </a>
            </v-col>

            <v-col align="center">
              <a href="https://nleomf.org/" target="_blank" class="text-white">
                <v-img
                  src="../assets/nleopmf.png"
                  width="60"
                  height="60"
                  class="mb-2"
                />

                National Law Enforcement <br />Officers Memorial Fund
              </a>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12"> </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider class="mb-5 mt-5"></v-divider>
      <v-row>
        <v-col cols="12" md="10">
          <div
            class="d-flex flex-wrap justify-md-start justify-center justify-md-none"
          >
            If your Department would like to participate in future Memorial
            Programs, please email info@lleom.com.
          </div>
        </v-col>
        <v-col class="text--secondary text-center text-md-right">
          {{ new Date().getFullYear() }} © LLEOM
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.bg-dark-blue {
  background-color: #001d3d !important;
  color: #ffffff !important;
}
</style>
