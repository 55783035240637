<template>
  <HeroAlt />
  <CallbackBlock />
  <NewsSection />
  <QuoteSection quote="May these walls never be filled." />
</template>

<script>
import HeroAlt from "../components/sections/HeroAlt.vue";
import CallbackBlock from "@/components/sections/CallbackBlock.vue";
import QuoteSection from "../components/sections/QuoteSection.vue";
import NewsSection from "../components/sections/NewsSection.vue";

export default {
  name: "HomeView",

  components: {
    HeroAlt,
    CallbackBlock,
    QuoteSection,
    NewsSection,
  },

  data: () => ({
    //
  }),
};
</script>
