<template>
  <section>
    <v-img width="100%" class="bg-black">
      <v-container fill-height>
        <v-row>
          <v-col>
            <h1
              class="text-md-h4 text-sm-h5 text-h7 mt-7 mb-7 text-center text-white"
            >
              "{{ quote }}"
            </h1>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </section>
</template>

<script>
export default {
  props: {
    quote: {
      type: String,
      default: () => "",
    },
  },
};
</script>
