<template>
  <v-row no-gutters>
    <v-col>
      <HeroSmall :heading="heading" />
      <ContentSection :textblocks="texts" />
      <QuoteSection quote="Heroes Live Forever" />
    </v-col>
  </v-row>
</template>

<script>
import HeroSmall from "@/components/sections/HeroSmall.vue";
import ContentSection from "@/components/sections/ContentSection.vue";
import QuoteSection from "@/components/sections/QuoteSection.vue";

export default {
  name: "HistoryView",

  components: {
    HeroSmall,
    ContentSection,
    QuoteSection,
  },

  data: () => ({
    heading: " History ",
    texts: [
      {
        content:
          "The late Sergeant Ron Cannatella of the New Orleans Police Department was inspired to dedicate a memorial for Louisiana’s fallen officers after meeting with Craig Floyd, the C.E.O. of the National Law Enforcement Officers Memorial in Washington DC.",
      },
      {
        content:
          "In 1995, Sgt. Cannatella was standing in front of Lake Lawn Metairie Funeral Home awaiting the procession of a police officer who was killed in the line of duty. The serene setting in front of the funeral home struck him as the perfect place for the monument.",
      },
      {
        content:
          "After four years of fundraising and much hard work, the Louisiana Law Enforcement Officers Memorial was dedicated on May 1, 2002 at Lake Lawn Metairie Cemetery.",
      },
      {
        content:
          "The Executive Board of the LLEOM is comprised of members of our states law enforcement and business community as well as surviving family members. The Executive Board was chaired by Melanie Cannatella, the widow of Sgt. Ron Cannatella from 2002-2015. In 2015 Matt Patin, who has been an active board member since 2004, was elected as Executive Director.",
      },
    ],
  }),
};
</script>
