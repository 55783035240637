<template>
  <v-row no-gutters>
    <v-col>
      <HeroSmall :heading="heading" />
      <ContentSection :textblocks="texts" />
      <QuoteSection quote="Honoring Service, Saluting Sacrifice" />
    </v-col>
  </v-row>
</template>

<script>
import HeroSmall from "@/components/sections/HeroSmall.vue";
import ContentSection from "@/components/sections/ContentSection.vue";
import QuoteSection from "@/components/sections/QuoteSection.vue";

export default {
  name: "ServicesView",

  components: {
    HeroSmall,
    QuoteSection,
    ContentSection,
  },

  data: () => ({
    heading: " Services ",
    texts: [
      {
        content:
          "Each year during National Police Week, a candlelight vigil and memorial service is held at the monument where the names of the previous year’s fallen officers are dedicated. The surviving family members are recognized and presented with a token of remembrance in honor of their loved one. Following the memorial service, the Louisiana Chapter of Concerns of Police Survivors (C.O.P.S.) holds a reception for the survivors, where previous years survivors are available as a means of support for the family and friends of the fallen officers.",
      },
    ],
  }),
};
</script>
