<template>
  <section>
    <v-img width="100%" height="20vh" class="gradient-fill" dark cover>
      <v-container fill-height>
        <v-row>
          <v-col>
            <h1
              class="text-md-h2 text-sm-h3 text-h4 mt-7 font-weight-black text-center"
            >
              {{ heading.toUpperCase() }}
            </h1>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </section>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: () => "",
    },
  },
};
</script>

<style scoped>
.gradient-fill .v-responsive__content {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    to right,
    rgba(3, 12, 41, 0.7),
    rgba(5, 11, 31, 0.7)
  );
}
</style>
