<template>
  <section>
    <v-img
      v-for="(item, i) in heroAlt"
      :key="i"
      width="100%"
      cover
      :src="item.src"
      class="gradient-fill EightyVh"
      dark
    >
      <v-container>
        <v-row
          justify="center"
          class="justify-center text-center stretch align-center EightyVh"
        >
          <v-col class="align-center justify-center">
            <h1
              class="text-md-h2 text-sm-h3 mt-7 text-h4 justify-center font-weight-black text-center"
            >
              LOUISIANA <br />
              LAW ENFORCEMENT <br />
              MEMORIAL
            </h1>

            <h3 class="text-center">
              <br />

              <!-- <v-alert class="text-black bg-warning">
                2024 Memorial Service - Wednesday May 8, 7pm</v-alert
              > -->

              <br />

              Lake Lawn Metairie Cemetery<br />
              5100 Pontchartrain Blvd<br />
              New Orleans, LA 70124
            </h3>
            <br />
            <PaypalDonate></PaypalDonate>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </section>
</template>

<script>
import PaypalDonate from "../paypalDonate.vue";
export default {
  props: {
    heroAlt: {
      type: Array,
      default: () => [
        {
          src: require(`@/assets/img/xx2_mini.jpg`),
          heading: "LOUISIANA LAW ENFORCEMENT MEMORIAL",
        },
      ],
    },
  },
  components: { PaypalDonate },
};
</script>

<style>
.gradient-fill .v-responsive__content {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    to right,
    rgba(3, 12, 41, 0.7),
    rgba(5, 11, 31, 0.7)
  );
}

.EightyVh {
  height: 80vh;
}
</style>
