<template>
    <v-row no-gutters>
        <v-col>
            <HeroSmall :heading="heading" />
            <ContentSection :textblocks="texts" showDonation="true" />
            <QuoteSection quote="Honoring Louisiana’s Fallen Heroes" />
        </v-col>
    </v-row>
</template>


<script>
import HeroSmall from '@/components/sections/HeroSmall.vue'
import ContentSection from '@/components/sections/ContentSection.vue'
import QuoteSection from '@/components/sections/QuoteSection.vue'

export default {
    name: 'SupportView',

    components: {
        HeroSmall,
        ContentSection,
        QuoteSection
    },

    data: () => ({

        heading: ' Support ',
        texts: [{ content: "As a nonprofit 501(c)(3) organization, the Louisiana Law Enforcement Officers Memorial relies on the generosity of individuals, organizations and corporations to carry out our work of honoring and remembering the heroes of Louisiana’s law enforcement." },
        { content: "We deeply appreciate the support of all of our donors. Among other things, your contributions enable us to maintain the Louisiana Law Enforcement Officers Memorial as a special place of honor that is visited by surviving family members and to hold the annual Candlelight Vigil during National Police Week." },
        { content: "Donations can be mailed to PO Box 851050, New Orleans, LA 70185 or made through Paypal:" }
        ]
    }),
}

</script>