<template>
  <div>
    <v-navigation-drawer v-model="drawer" fixed app temporary>
      <v-list dense>
        <v-list-item-group
          v-for="(item, i) in items"
          :key="i"
          color="secondary"
        >
          <v-list-item :to="item.to">
            <v-list-item-content>
              <v-list-item-title v-text="item.title.toUpperCase()" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar fixed app hide-on-scroll height="64" elevate-on-scroll>
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = true" />

      <img :src="require(`@/assets/img/logo_small.png`)" />

      <div class="full-width">
        <v-btn
          depressed
          tile
          v-for="name in items"
          :key="name.title"
          plain
          class="py-8 hidden-sm-and-down"
          :to="name.to"
          >{{ name.title }}</v-btn
        >
      </div>
      <v-spacer />
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "siteHeader",

  data() {
    return {
      clipped: false,
      drawer: false,
      items: [
        {
          title: "Home",
          to: "/",
        },
        {
          title: "About",
          to: "/about",
        },
        {
          title: "Services",
          to: "/services",
        },
        {
          title: "History",
          to: "/history",
        },
        {
          title: "Gallery",
          to: "/gallery",
        },
        {
          title: "Partners",
          to: "/partners",
        },
        {
          title: "Support",
          to: "/support",
        },
      ],
    };
  },
};
</script>

<style>
.full-width {
  text-align: center;
  margin-left: -75px;
  width: 100%;
}
</style>
