<template>
  <v-row no-gutters>
    <v-col>
      <HeroSmall :heading="heading" />
      <ContentSection :textblocks="texts" />
      <QuoteSection
        quote="It is not how these officers died that made them heroes, but how they lived."
      />
    </v-col>
  </v-row>
</template>

<script>
import HeroSmall from "@/components/sections/HeroSmall.vue";
import ContentSection from "@/components/sections/ContentSection.vue";
import QuoteSection from "@/components/sections/QuoteSection.vue";

export default {
  name: "AboutView",

  components: {
    HeroSmall,
    ContentSection,
    QuoteSection,
  },

  data: () => ({
    heading: "About",

    texts: [
      {
        content:
          "The Louisiana Law Enforcement Officers Memorial (LLEOM) is a non-profit organization dedicated to honoring the lives and memories of Louisiana Police Officers, Deputy Sheriffs, State Troopers, Correctional Officers, and Federal Agents killed in the line of duty.",
      },
      {
        content:
          "The LLEOM works hard to generate increased public support for the law enforcement profession by permanently recording and appropriately commemorating the service and sacrifice of our fallen heroes.",
      },
    ],
  }),
};
</script>
