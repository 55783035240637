import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/HomeView.vue";
import About from "@/views/AboutView.vue";
import Services from "@/views/ServicesView.vue"
import History from "@/views/HistoryView.vue"
import Gallery from "@/views/GalleryView.vue"
import Partners from "@/views/PartnersView.vue"
import Support from "@/views/SupportView.vue"
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/history",
    name: "History",
    component: History,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery,
  },
  {
    path: "/partners",
    name: "Partners",
    component: Partners,
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
});

export default router;