<template>
  <v-container>
    <v-row no-gutters>
      <v-col>
        <div class="pa-lg-6 pa-md-10 px-4 py-6">
          <h3
            class="text-h5 text-uppercase font-weight-thin text-left my-8"
            v-for="(block, i) in textblocks"
            :key="i"
          >
            {{ block.content }}
          </h3>
          <paypalDonate v-if="showDonation"></paypalDonate>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import paypalDonate from "@/components/paypalDonate.vue";

export default {
  components: {
    paypalDonate,
  },
  props: {
    textblocks: {
      type: Array,
      default: () => [],
    },
    showDonation: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
