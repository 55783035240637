<template>

<v-container class="py-16">
    <v-row>
      <v-col
        v-for="n in galleryImages"
        :key="n"
        class="d-flex child-flex"
        cols="4"
        xl="3"
      >
        <v-img
          :src="require(`@/assets/img/gallery/resized/${n.name}.jpg`)"
          :lazy-src="require(`@/assets/img/gallery/resized/${n.name}.jpg`)"
          aspect-ratio="1"
          class="grey lighten-2 rounded-lg"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-container>


</template>

<script>

export default {
  props: {
    galleryImages: {
      type: Array,
      default: () => [],
    },
   
  },
};

</script>