<template>
  <v-row no-gutters>
    <v-col cols="12">
      <HeroSmall :heading="heading" />
    </v-col>
  </v-row>

  <v-container>
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <div class="pa-lg-6 pa-md-5 px-4 py-6 text-center">
          <div v-for="(partner, i) in partners" :key="`partner-${i}`">
            <p v-if="partner.url" class="mb-7">
              <a
                target="_blank"
                class="text-white"
                :href="partner.url"
                v-text="partner.name"
              />
            </p>
            <p v-else class="text-white mb-7" v-text="partner.name" />
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="pa-lg-6 pa-md-5 px-4 py-6 text-center">
          <div v-for="(partner, i) in partners2" :key="`partner2-${i}`">
            <p v-if="partner.url" class="mb-7">
              <a
                target="_blank"
                class="text-white"
                :href="partner.url"
                v-text="partner.name"
              />
            </p>
            <p v-else class="text-white mb-7" v-text="partner.name" />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>

  <QuoteSection quote="In Valor There Is Hope" />
</template>

<script>
import HeroSmall from "@/components/sections/HeroSmall.vue";
import QuoteSection from "@/components/sections/QuoteSection.vue";

export default {
  name: "PartnersView",

  components: {
    HeroSmall,
    QuoteSection,
  },

  data: () => ({
    heading: " Partners ",
    partners: [
      {
        name: "Jefferson Parish Sheriff's Office",
        url: "https://www.jpso.com/",
      },
      {
        name: "Jefferson Parish Fire Department",
        url: "https://www.jeffparish.net/departments/fire--eastbank-consolidated-fire-",
      },
      { name: "Attiki Bar & Grill", url: "http://www.attikineworleans.com/" },
      { name: "Omni Royal Hotel", url: "" },
      { name: "Derik Schumacher", url: "" },
      {
        name: "Emerald Society of New Orleans",
        url: "http://www.emeraldsocietyofneworleans.com/",
      },
      { name: "Event Producers", url: "http://www.eventproducers.com/" },
      { name: "Fleur de Lis Rentals", url: "" },
      {
        name: "Mr. and Mrs. Joseph Georgusis in memory of Joey Georgusis",
        url: "",
      },
    ],
    partners2: [
      { name: "Police Association of New Orleans", url: "" },
      {
        name: "Lake Lawn Metairie Funeral Home and Cemeteries",
        url: "https://www.dignitymemorial.com/funeral-homes/new-orleans-la/lake-lawn-metairie-funeral-home/9785",
      },
      {
        name: "Louisiana Concerns of Police Survivors",
        url: "http://www.la-cops.org/",
      },
      {
        name: "New Orleans Police Department",
        url: "https://www.nola.gov/nopd/",
      },
      {
        name: "New Orleans Fire Department",
        url: "https://www.nola.gov/nofd/",
      },
      { name: "New Orleans E.M.S.", url: "https://www.nola.gov/ems/" },
      {
        name: "Perfect Presentations",
        url: "http://www.perfectpresentationsflorals.com/",
      },
      { name: "River Parish Disposal", url: "https://bizstinks.com/" },
    ],
  }),
};
</script>
